import { Injectable } from '@angular/core';
import {MessageService} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class CustomMessageService {

  constructor(private messageService: MessageService) { }

  showSuccess(summary: string, detail: string) {
    this.messageService.add({life: 1500, severity:'success', summary: summary, detail: detail});
  }

  showInfo(summary: string, detail: string) {
    this.messageService.add({life: 3000, severity:'info', summary: summary, detail: detail});
  }

  showWarn(summary: string, detail: string) {
    this.messageService.add({life: 5000, severity:'warn', summary: summary, detail: detail});
  }

  showError(summary: string, detail: string) {
    this.messageService.add({life: 7000, severity:'error', summary: summary, detail: detail});
  }
}
